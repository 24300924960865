:root {
  --background-dim: #ebecf0;
  --primary: #173357;
  --sjs-primary-backcolor: #153258;
  --sjs-border-default: #92a1b5;
  --sjs-secondary-backcolor: #0b8be3;
  --sjs-general-backcolor-dim: #f1f2f4;
  --sjs-general-forecolor-light: #065990;
  --sjs-secondary-backcolor-semi-light: rgba(23, 51, 87, 0.05);
  --sjs-primary-backcolor-light: rgba(11, 139, 227, 0.15);
  --foreground-light: #617082;
  --foreground: black;
  --sjs-editor-background: #f1f2f4;
  --sjs-shadow-inner: none;
  --sjs-editorpanel-cornerRadius: none;
  --sjs-secondary-backcolor-light: rgba(11, 139, 227, 0.5);
  --sjs-font-questiontitle-color: #000;
  --blue-light: rgba(11, 139, 227, 0.15);
  --sjs-general-forecolor: #161616;
  --sjs-special-yellow-light: rgba(23, 51, 87, 0.05);
  --sjs-primary-backcolor-dark: #45658D;
  --sjs-special-yellow: #0b8be3;

  .svc-btn {
    --sjs-questionpanel-hovercolor: #0b8be3;
    --sjs-general-backcolor: transparent;
    --sjs-general-forecolor-light: #92a1b5;
    --foreground-light: #ffff;
    border: 1px solid #0b8be3;

    &:focus {
      box-shadow: none;
    }

    &:hover .svc-page__question-type-selector {
      & use {
        fill: #fff;
      }
      &:hover use {
        fill: #92a1b5 !important;
      }
    }
    .svc-text {
      --sjs-primary-backcolor: #086cb0;
    }
  }

  .svc-question__content .sd-panel__content {
    overflow: hidden;
  }
  .svc-row .sd-row.sd-row--multiple {
    overflow: hidden;
  }


  .svc-item-value-controls .sv-svg-icon use {
    fill: #086cb0;
  }

  .svc-page {
    --sjs-general-forecolor-light: #617082;
  }

  /* properties section */
  .svc-side-bar {
    --sjs-general-forecolor-light: #617082;
    --background-dim: rgba(23, 51, 87, 0.07);
    --border: #edebe9;
    --sjs-border-default: #edebe9;

    .spg-checkbox {
      &:hover {
        --sjs-general-backcolor-dim: #ebecf0;
      }
    }

    .spg-checkbox__rectangle {
      --background: transparent;
      --sjs-border-default: #0b8be3;
    }

    .sv-button-group__item:hover {
      --sjs-general-backcolor-dim: #45658d;
    }

    .spg-panel__content {
      --sjs-border-default: #92a1b5;
    }

    .sv-button-group__item {
      --background: #173357;
      --sjs-general-forecolor: #ffffff;
      --sjs-primary-backcolor: #ffffff;
      --primary: #5c88bf;

      &.sv-button-group__item--selected {
        background-color: var(--primary);
      }
    }
  }
  .svc-tab-designer .sd-container-modern.sd-container-modern--static {
    max-width: 90% !important;
  }

  .spg-panel__title--expandable:not(.spg-panel__title[aria-expanded="true"]):focus,
  .spg-panel__title--expandable:not(.spg-panel__title[aria-expanded="true"]):hover {
    color: #173357;
  }

  #svd-save .sv-action-bar-item.sv-action-bar-item--icon .sv-svg-icon.sv-action-bar-item__icon {
    width: 56px !important;
    height: 30px !important;
    margin-top: -3px;
  }

  #svd-save-theme {
    visibility: hidden;
  }

  .svc-btn:hover.svc-btn:not(.svc-logic-tab__content-action--disabled) .svc-text {
    color: #ffffff;
  }

  .svc-logic-paneldynamic .svc-logic-operator {
    border-radius: 0;
  }

  .sl-panel .sl-panel__footer button.sl-panel__done-button {
    border-radius: 0;
  }

  .svc-action-button {
    border-radius: 0;
  }

  .sv-popup__body-footer-item.sd-btn--danger {
    outline: none;
    margin: 0 10px;
    cursor: pointer;
    background-color: #153258;
    color: #ffffff;
    border: 1px solid #153258;

    &:hover {
      background-color: #45658d;
      border: 1px solid #45658d;
    }

    &:active {
      background-color: #5c88bf;
      border: 1px solid #5c88bf;
    }
  }

  .sv-popup__body-footer-item.sd-btn--small:not(.sd-btn--danger) {
    background-color: #ffffff;
    color: #0b8be3;
    border: 1px solid #0b8be3;

    &:hover {
      background-color: #0b8be3;
      color: #ffffff;
    }
    &:active {
      background-color: #0b8be3;
      color: #ffffff;
    }
  }

  .portal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    background-color: var(--background-semitransparent, rgba(144, 144, 144, 0.5));
  }

  .portal-overlay .confirm-dialog {
    z-index: 1000000000000111;
    padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: white;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
    box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  }

  .confirm-dialog .header {
    color: #173357;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    width: 400px;
    line-height: 41px;
    font-size: 30px;
  }

  .confirm-dialog .body {
    color: #173357;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    width: 400px;
    line-height: 22px;
    font-size: var(--sjs-font-size, 16px);
    line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  }

  .portal-overlay .confirm-dialog__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .btn {
    outline: none;
    border: 1px solid #000;
    margin: 0 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: var(--sjs-font-size, 18px);
    border-radius: var(--sjs-corner-radius, 4px);
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }

  .yes-btn {
    background-color: #153258;
    color: #ffffff;
    border: 1px solid #153258;

    &:hover {
      background-color: #45658d;
      border: 1px solid #45658d;
    }

    &:active {
      background-color: #5c88bf;
      border: 1px solid #5c88bf;
    }
  }

  .no-btn {
    background-color: #ffffff;
    color: #0b8be3;
    border: 1px solid #0b8be3;

    &:hover {
      background-color: #0b8be3;
      color: #ffffff;
    }
    &:active {
      background-color: #0b8be3;
      color: #ffffff;
    }
  }
  .cke {
    border: none;
  }

  .cke_top {
    background: transparent;
  }

  .cke_bottom {
    background: transparent;
  }

  .cke_dialog_contents_body {
    padding: 40px;
  }

  .cke_dialog_tabs {
    padding-left: 10px;
  }

  .cke_dialog_title {
    background-color: transparent;
    border-bottom: none;
    font-family: "Open Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 40px;
    padding-top: 30px;
  }

  .cke_dialog_close_button {
    padding: 20px;
  }

  .cke_dialog_ui_labeled_label {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cke_dialog_contents {
    border-top: none;
  }

  .cke_dialog_tab {
    border: none;
    background: none;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cke_dialog_tab_selected {
    border-bottom: 2px solid #0078D4;
    background: transparent;
  }
  .cke_dialog_tab_selected:hover, .cke_dialog_tab_selected:focus {
    background: transparent;
    border-bottom: 2px solid #0078D4;
  }

  .cke_dialog_tab:focus {
    border: none;
    border-bottom: 2px solid #0078D4;
  }


  .cke_dialog_ui_input_select .cke_dialog_ui_input_select {
    padding: 8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 40px;
    border-radius: 0px;

    & option {
      line-height: 20px;
      font-weight: 400;
      font-family: "Open Sans";
      font-size: 14px;
    }
  }

  .cke_dialog_ui_input_text[type="text"], .cke_dialog_ui_input_tel[type="tel"] {
    padding: 8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 40px;
  }

  .cke_dialog_footer {
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: none;
    background: #F0F5F8;

    .cke_ltr .cke_dialog_footer_buttons .cke_dialog_ui_hbox_first, .cke_ltr .cke_dialog_footer_buttons .cke_dialog_ui_hbox_child {
      padding-right: 10px;
    }

    .cke_dialog_ui_button_ok {
      width: 70px;
      background-color: #153258;
      color: #ffffff;
      border: 1px solid #153258;

      &:hover {
        background-color: #45658d;
        border: 1px solid #45658d;
      }

      &:active {
        background-color: #5c88bf;
        border: 1px solid #5c88bf;
      }
    }

    .cke_dialog_ui_button_cancel {
      width: 70px;
      background-color: #ffffff;
      color: #0b8be3;
      border: 1px solid #0b8be3;

      &:hover {
        background-color: #0b8be3;
        color: #ffffff;
      }
      &:active {
        background-color: #0b8be3;
        color: #ffffff;
      }
    }

    .cke_resizer.cke_resizer_ltr {
      visibility: hidden !important;
    }
  }
}

div[data-name="backgroundImage"],
div[data-name="imageLink"],
div[data-name="logo"]   {
  .spg-input-container__input {
  display:none;    
}
  .spg-input-container__buttons-container{
      margin-left: auto;
      margin-right: 0;
  }

  .cd_error_file_size:after{
      content: "The file size should not exceed 5 MB.";
      color:red
  }
  .spg-input.spg-input-container.spg-file-edit.gray{
      background-color: #f1f2f4;
  }
}

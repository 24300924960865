:root {
  --background-dim: #ebecf0;
  --primary: #173357;
  --sjs-border-default: #92a1b5;
  --sjs-secondary-backcolor: #0b8be3;
  --sjs-general-backcolor-dim: #f1f2f4;
  --sjs-general-forecolor-light: #065990;
  --sjs-secondary-backcolor-semi-light: rgba(23, 51, 87, 0.05);
  --sjs-primary-backcolor-light: rgba(11, 139, 227, 0.15);
  --foreground-light: #617082;
  --foreground: black;
  --sjs-editor-background: #f1f2f4;
  --sjs-shadow-inner: none;
  --sjs-editorpanel-cornerRadius: none;
  --sjs-secondary-backcolor-light: rgba(11, 139, 227, 0.5);
  --sjs-font-questiontitle-color: #000;
  --sjs-questionpanel-hovercolor: #ebecf0;
  --blue-light: rgba(11, 139, 227, 0.15);
  --sjs-general-forecolor: #161616;
  --sjs-special-yellow-light: rgba(23, 51, 87, 0.05);
  --sjs-primary-backcolor-dark: #45658d;
  --sjs-primary-backcolor: #173357;

  /*date picker*/

  .react-datepicker {
    border: none !important;
  }

  .react-datepicker-popper {
    position: absolute !important;
  }

  .react-datepicker-wrapper {
    width: 100%;

    & input {
      appearance: none;
      position: static;
      width: 100%;
      height: calc(6 * var(--base-unit, 8px));
      box-sizing: border-box;
      padding: calc(1.5 * var(--sjs-base-unit, var(--base-unit, 8px))) calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
      line-height: calc(3 * var(--base-unit, 8px));
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: calc(2 * var(--base-unit, 8px));
      color: var(--foreground, #161616);
      border: none !important;
      background: #f1f2f4;
      text-align: left;

      &:focus {
        outline: none !important;
        box-shadow: 0 0 0 2px var(--sjs-primary-backcolor) !important;
      }
    }
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border: none !important;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: #ffffff !important;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: var(--sjs-primary-backcolor) !important;
    color: #ffffff !important;
  }

  .react-datepicker__header {
    background-color: transparent !important;
    border-bottom: none !important;
  }

  .react-datepicker__day-name {
    font-weight: 700;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: var(--sjs-primary-backcolor) !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: var(--sjs-primary-backcolor) !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    color: #ffffff;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: var(--sjs-primary-backcolor) !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    color: #ffffff !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: var(--sjs-primary-backcolor) !important;
  }

  .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover, .react-datepicker__year-option:hover {
    background-color: var(--sjs-primary-backcolor) !important;
  }

  .react-datepicker__year-option:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming)  {
    height: 20px !important;
  }

  .react-datepicker__year-option:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming):hover {
    background: var(--sjs-primary-backcolor) !important;
  }

  .react-datepicker__year-option:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming):hover .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    border-color: #ffffff !important;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    transform: rotate(315deg);
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    top: 6px;
    width: 9px;
  }

  .react-datepicker__year-option:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous)  {
    height: 20px !important;
  }

  .react-datepicker__year-option:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous):hover{
    background: var(--sjs-primary-backcolor) !important;
  }

  .react-datepicker__year-option:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous):hover .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    border-color: #ffffff !important;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    transform: rotate(135deg);
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    top: 0px;
    width: 9px;
  }

  .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-color: var(--sjs-primary-backcolor) !important;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: var(--sjs-primary-backcolor) !important;
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
  }

  .sd-panel__content {
    padding-top: 0 !important;
  }

  .sd-element--complex > .sd-element__header--location-top:after{
    content: none;
  }

  .sd-element--complex.sd-element--with-frame > .sd-element__header--location-top {
    padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
  }

  .sd-element--complex.sd-element--with-frame {
    padding-top: var(--sd-base-vertical-padding) !important;
  }

  & div[data-name="countrycode"] .sv-popup__container {
    width: 534px !important;
  }

  .sd-element--complex .sd-question__content div[data-key^="code"] {
    padding-left: 8px !important;
  }
  .sd-element--complex .sd-question__content div[data-key^="phone"] {
    padding-left: 8px !important;
  }

  .sd-element--complex.sd-element--with-frame > .sd-element__header {
    padding-top: 0 !important;
  }
}
